import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import persist from '@alpinejs/persist'
import Clipboard from '@ryangjchandler/alpine-clipboard'

Alpine.plugin(Clipboard)

const IMG_CDN = 'https://images.standlee.com';

Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(persist);

window.Alpine = Alpine;

Alpine.store('sideNav', {
  open: false,

  close() {
    this.open = false;
  },

  toggle() {
    this.open = !this.open;

    if(this.open) {
      document.body.classList.add('offscreen-nav-visible');
      Alpine.store('siteSearch').closeSearch();
      return;
    }
    document.body.classList.remove('offscreen-nav-visible');
  }
});

Alpine.store('siteSearch', {
  init() {
    let headerSearchVisible = document.querySelector('.large-nav').getBoundingClientRect().width;
    let params = new URLSearchParams(window.location.search);
    let searchParam = params.get('search');
    if(searchParam) {
      if(blogSearch) {
        this.blogSearchTerm = searchParam;
      } else if(headerSearchVisible) {
        this.openHeaderSearch();
        this.headerSearchTerm = searchParam;
      } else {
        this.openSearch();
        this.searchTerm = searchParam;
      }
    }
  },

  open: false,
  headerSearchOpen: false,
  currentScroll: 0,
  blogSearchTerm: '',
  headerSearchTerm: '',
  searchTerm: '',

  clearBlogSearch() {
    this.resetSearch();
  },

  clearHeaderSearch() {
    this.resetSearch();
  },

  clearSearch() {
    this.resetSearch();
  },

  closeSearch() {
    this.open = false;
    this.resetSearch();
    if(!(Alpine.store('sideNav').open)) {
      document.body.classList.remove('offscreen-nav-visible', 'search-overlay');
    }
  },

  openHeaderSearch() {
    this.headerSearchOpen = true;
  },

  openSearch() {
    Alpine.store('sideNav').close();
    this.open = true;
    document.body.classList.add('offscreen-nav-visible', 'search-overlay');
  },

  resetSearch() {
    this.currentScroll = window.scrollY;
    this.searchTerm = '';
    this.headerSearchTerm = '';
    this.blogSearchTerm = '';
    searchui.clear();
    headersearchui.clear();

    if(blogSearch) {
      blogSearchui.clear();
      window.scrollTo(0, this.currentScroll);
    }
  }
});

Alpine.data('headerSearch', () => ({
  init() {
    Alpine.effect(() => {
      let headerSearchStatus = Alpine.store('siteSearch').headerSearchOpen;
      if(headerSearchStatus) {
        this.open();
      }
    })
  },
  close() {
    if(!Alpine.store('siteSearch').headerSearchOpen) { return }
    Alpine.store('siteSearch').headerSearchOpen = false;
    Alpine.store('siteSearch').resetSearch();
    this.$focus.focus(document.querySelector('#header-search-toggle'));
  },

  open() {
    if(Alpine.store('siteSearch').headerSearchOpen) { return }
    Alpine.store('siteSearch').headerSearchOpen = true;
    document.body.classList.remove('offscreen-nav-visible', 'search-overlay');
    this.$focus.focus(document.querySelector('#header-search-input'));
  },

  toggle() {
    if(Alpine.store('siteSearch').headerSearchOpen) {
      this.close();
    } else {
      this.open();
    }
  }
}));

Alpine.data('headerSearchToggle', () => ({
  toggle() {
    this.$dispatch('toggle-header-search');
  }
}))

Alpine.data('sideNav', () => ({
  init() {
    this.currentLocation = window.location.pathname;
  },
  active: null,
  currentLocation: ''
}))


Alpine.data(
  "thumbnailGallery",
  (imagePath, imageAlt, imageWidth) => ({
    init() {
      if(imagePath) {
        this.currentImage = imagePath;
        this.alt = imageAlt;
        this.width = imageWidth;
      }
    },
    selectImage(imagePath, imageAlt, width, height) {
      if(imagePath === this.currentImage) {
        return;
      }
      this.loading = true;
      let loaded = document.querySelector('#main-product-image');

      loaded.addEventListener("load", () => {
        this.loading = false;
      }, {once: true });

      if(width) {
        this.width = width
      } else {
        this.width = '656';
      }
      if(height) {
        this.height = height
      } else {
        this.height = '656';
      }
      this.currentImage = imagePath;
      this.alt = imageAlt;
    },
    currentImageSrc() {
      return {
        src: `${IMG_CDN}${this.currentImage}?auto=format&width=${this.width}&canvas=656,656`,
        srcset: `${IMG_CDN}${this.currentImage}?auto=format&width=${this.width}&dpr=2&q=40&canvas=1312,1312 2x`,
      }
    },
    currentImage: "",
    alt: "",
    height: "",
    width: "",
    loading: false,
  })
);


Alpine.data('sideNavDropdown', (id= '') => ({
  get expanded() {
    return this.active === id;
  },
  set expanded(value) {
    this.active = value ? id : null
  }
}));

Alpine.data('siteSearch', () => ({
  open: false,

  openSearch() {
    this.open = true;
  }
}))

// Podcast transcript toggle
Alpine.data('transcriptToggle', () => ({
  expanded: false,
  toggle() {
    this.expanded = !this.expanded;
  },
}));


// Email subscribe form
Alpine.data('emailSubscribe', (el) => ({

  init() {
    this.thanksUrl = el.dataset.redirect;
    if(el.dataset.nestedModal) {
      this.inModal = el.dataset.nestedModal;
    }
  },

  async handleSubmit(e) {
    this.submitting = true;
    const res = await fetch('/.netlify/functions/email_subscribe', {
      method: 'POST',
      body: JSON.stringify(this.inputs),
    }).then((res) => {
      if(res.ok) {
        if(this.inModal) {
          this.closeModal()
        }
        const gtm_modal_event = e.target.dataset.gtmModalEvent
        if (gtm_modal_event) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({'event': gtm_modal_event});
        }
        window.location = this.thanksUrl;
      }
    });
  },

  inputs: {
    birthday: null,
    email: "",
    interests: []
  },

  thanksUrl: "",
  inModal: false,
  submitting: false

}));

// Promotion email subscribe form, sets custom attribute that should be changed per promo
Alpine.data('promoEmailSubscribe', (el) => ({

  init() {
    this.thanksUrl = el.dataset.redirect;
    if(el.dataset.nestedModal) {
      this.inModal = el.dataset.nestedModal;
    }
  },

  async handleSubmit(e) {
    this.submitting = true;
    const res = await fetch('/.netlify/functions/promo_email_subscribe', {
      method: 'POST',
      body: JSON.stringify(this.inputs),
    }).then((res) => {
      if(res.ok) {
        if(this.inModal) {
          this.closeModal()
        }
        const gtm_form_submission_event = e.target.dataset.gtmModalEvent
        if (gtm_form_submission_event) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({'event': gtm_form_submission_event});
        }
        window.location = this.thanksUrl;
      }
    });
  },

  inputs: {
    email: ""
  },

  thanksUrl: "",
  submitting: false

}));


// Contact form selector
Alpine.data('formSelector', () => ({
  showForm(selectedForm) {
    this.selectedForm = selectedForm;
    setTimeout(
      function() {
        document.querySelector(`#${selectedForm}`).iFrameResizer.resize()
      }
    , 350)
  },
  selectedForm: ''
}));

// Product Filtering
Alpine.data('filterProducts', (selectedId) => ({
  init() {
    this.hideEmptyCategories();
    this.urlParams = new URLSearchParams(location.search);;
    if(this.urlParams.has('species')) {
      let speciesParam = this.urlParams.get('species')
      if(this.speciesList.includes(speciesParam)) {
        this.currentSpecies = speciesParam;
      }
    }
  },

  filterBySpecies(species) {
    this.currentSpecies = species;

    this.urlParams.set('species', species)
    history.replaceState(null, null, "?"+ this.urlParams.toString());

    this.$nextTick(() => {
      this.hideEmptyCategories();
    });
  },

  hideEmptyCategories() {
    this.$nextTick(() => {
      document.querySelectorAll('.product-card-section').forEach(productGroup => {
        let categoryHasProducts = !Array.from(productGroup.querySelectorAll('.product-card')).every(productCard => productCard.style.display == 'none');
        if(categoryHasProducts) {
          productGroup.classList.remove('hide');
        } else {
          productGroup.classList.add('hide');
        }
      })
    })
  },

  isCurrentSpecies(el) {
    if(el.dataset.species.includes(this.currentSpecies)) {
      return true;
    }
    return false;
  },

  currentSpecies: '',
  speciesList: ["cattle", "chinchilla", "goat", "guinea-pig", "hamster-gerbil", "mouse-rat", "other-livestock", "other-small-animal", "pig", "rabbit", "sheep"],
  urlParams: ''
}));

// Tabs
Alpine.data('tabs', () => ({
  selectedId: null,
  init() {
      // Set the first available tab on the page on page load.
      this.$nextTick(() => this.select(this.$id('tab', 1)))
  },
  select(id) {
    this.selectedId = id
  },
  isSelected(id) {
      return this.selectedId === id
  },
  whichChild(el, parent) {
      return Array.from(parent.children).indexOf(el) + 1
  }
}));

Alpine.bind('TabButton', () => ({
  type: 'button',

  '@click'() {
    return this.select(this.$el.id)
  },

  ':aria-selected'() {
    return this.isSelected(this.$el.id)
  },

  ':class'() {
    return this.isSelected(this.$el.id) ? 'active' : ''
  },

  ':id'() {
    return this.$id('tab', this.whichChild(this.$el.parentElement, this.$refs.tablist))
  },

  ':tabindex'() {
    return this.isSelected(this.$el.id) ? 0 : -1
  }

}));

Alpine.bind('TabList', () => ({
  '@keydown.right.prevent.stop'() {
    return this.$focus.wrap().next()
  },

  '@keydown.home.prevent.stop'() {
    return this.$focus.first()
  },

  '@keydown.pageup.prevent.stop'() {
    return this.$focus.first()
  },

  '@keydown.left.prevent.stop'() {
    return this.$focus.wrap().prev()
  },

  '@keydown.end.prevent.stop'() {
    return this.$focus.last()
  },

  '@keydown.page-down.prevent.stop'() {
    return this.$focus.last()
  },
}));

Alpine.bind('TabPanel', () => ({
  'x-show'() {
    return this.isSelected(this.$id('tab', this.whichChild(this.$el, this.$el.parentElement)))
  },

  ':aria-labelledby'() {
    return this.$id('tab', this.whichChild(this.$el, this.$el.parentElement))
  },

}));

// Tabs
Alpine.data('timeline', () => ({
  selectedId: null,
  init() {
      // Set the first available timeline section on the page on page load.
      this.$nextTick(() => this.select(this.$id('timeline-section', 1)));
      const el = document.querySelector('#stick-timeline')
      const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle('is-stuck', e.intersectionRatio < 1),
        { threshold: [1] }
      );

      observer.observe(el);
  },
  select(id) {
    this.selectedId = id
  },
  isSelected(id) {
      return this.selectedId === id
  },
  whichChild(el, parent) {
      return Array.from(parent.children).indexOf(el) + 1
  }
}));

Alpine.bind('TimelineButton', () => ({
  type: 'button',

  '@click'() {
    let timeline = document.querySelector('#timeline-top');
    timeline.scrollIntoView({behavior: 'smooth'});
    return this.select(this.$el.id)
  },

  ':aria-selected'() {
    return this.isSelected(this.$el.id)
  },

  ':class'() {
    return this.isSelected(this.$el.id) ? 'active' : ''
  },

  ':id'() {
    return this.$id('timeline-section', this.whichChild(this.$el.parentElement, this.$refs.timelinelist))
  },

  ':tabindex'() {
    return this.isSelected(this.$el.id) ? 0 : -1
  }

}));

Alpine.bind('TimelineList', () => ({
  '@keydown.right.prevent.stop'() {
    return this.$focus.wrap().next()
  },

  '@keydown.home.prevent.stop'() {
    return this.$focus.first()
  },

  '@keydown.pageup.prevent.stop'() {
    return this.$focus.first()
  },

  '@keydown.left.prevent.stop'() {
    return this.$focus.wrap().prev()
  },

  '@keydown.end.prevent.stop'() {
    return this.$focus.last()
  },

  '@keydown.page-down.prevent.stop'() {
    return this.$focus.last()
  },
}));

Alpine.bind('TimelinePanel', () => ({
  'x-show'() {
    return this.isSelected(this.$id('timeline-section', this.whichChild(this.$el, this.$el.parentElement)))
  },

  ':aria-labelledby'() {
    return this.$id('timeline-section', this.whichChild(this.$el, this.$el.parentElement))
  },

}));


// Promo roadblock modal
Alpine.data('promoModal', () => ({
  init() {
    this.openModal();
  },

  closeModal() {
    document.body.classList.remove('modal-visible', 'modal-overlay');
    this.open = false;
    localStorage.setItem(this.modalName, 'shown')
  },

  openModal() {
    let modalViewed = localStorage.getItem(this.modalName);
    if(modalViewed !== 'shown') {
      this.open = true;
      document.body.classList.add('modal-visible', 'modal-overlay');
    }
  },

  open: false,
  modalName: 'fyp-2024-promo-modal-signup'
}));


// Accordions
Alpine.data('accordion', () => ({
  expanded: false
}))

Alpine.bind('AccordionContent', () =>({
  'x-collapse': '',
  'x-show': 'expanded'

}));

Alpine.bind('AccordionTrigger', () =>({
  '@click': 'expanded = !expanded',
}));


// Horse Personality Quiz
Alpine.store('personalityQuizAnswers', {
  init() {
    this.answers = window.quizAnswerKey;
    this.resultsPath = window.quizResultsPath;
  },

  answers: [],
  selections: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  result: '',
  resultsPath: '',

  calculateAnswers() {
    this.selections.forEach(item => {
      let foundAnswer = this.answers.find(answer => answer.value == item);
      if(foundAnswer) {
        foundAnswer.count++;
      }
    });

    let answers = [...this.answers];
    let sortedAnswers = answers.sort((a, b) => (a.count < b.count) ? 1 : (a.count > b.count) ? -1 : 0);
    this.result = sortedAnswers[0].slug;
    window.location = `${this.resultsPath}${this.result}`;
  },

  selectionsIncomplete() {
    return this.selections.some(item => item == 0);
  }
});


Alpine.data('personalityQuiz', () => ({
  selectedId: '',

  selected(answerId, questionNumber) {
    let questionSelected = Alpine.store('personalityQuizAnswers').selections[questionNumber - 1];

    if (questionSelected && (answerId != this.selectedId)) {
      return true;
    }
  },

  selectAnswer() {
    const questionNumber = this.$el.dataset.questionNumber;
    const answerValue = this.$el.dataset.optionValue;
    this.selectedId = answerValue;

    Alpine.store('personalityQuizAnswers').selections[questionNumber - 1] = answerValue;
  }
}))

Alpine.data('copyToClipboard', (text, buttonLabel = "Copy Link") => ({
  init() {
    this.copyText = text;
    this.buttonText = buttonLabel;
    this.initialButtonText = buttonLabel;
  },
  copyText: '',
  buttonText: 'Copy Link',
  initialButtonText: '',

  copied() {
    this.buttonText = 'Copied!',
    window.dataLayer.push({
      'event': 'quiz_copy_code',
    });
    setTimeout(() => {
      this.buttonText = this.initialButtonText;
      document.activeElement.blur()
    }, 3000);
  }
}));


// Must be after all Alpine related code
Alpine.start();


const isDesktop = window.matchMedia('(min-width: 950px)');

const handleResize = e => {
  if (e.matches) {
    Alpine.store('sideNav').open = false;
    document.body.classList.remove('offscreen-nav-visible');
    if(Alpine.store('siteSearch').open) {
      Alpine.store('siteSearch').closeSearch();
    }
  }
};

isDesktop.addEventListener('change', e => handleResize(e));

handleResize(isDesktop);


// File input validation
let fileUploadForms = Array.from(document.querySelectorAll('.file-upload-form'));
if(fileUploadForms.length) {
  fileUploadForms.forEach(fileUploadForm => {
    fileUploadForm.querySelectorAll('.file-input').forEach(input => input.addEventListener("change", limitUploadSize))
  })
}

function limitUploadSize(e) {
  let parentForm = document.querySelector(`form[name="${e.target.form.name}"]`)
  let fileInputs = parentForm.querySelectorAll('.file-input');
  let fileSizes = [];
  let fileSizeTotal = 0;

  fileInputs.forEach(input => {
    if(input.files[0]) {
      let fileInputSize = Number((input.files[0].size / 1024 / 1024).toFixed(2));

      fileSizes.push({
        name: input.files[0].name,
        size: fileInputSize
      });

      if(fileSizeTotal + fileInputSize <= 8) {
        fileSizeTotal += fileInputSize;
        input.nextElementSibling.textContent = `${fileInputSize} MB`;
      }
      else {
        let fileList = '';
        fileSizes.forEach(fileSize => fileList += `${fileSize.name} - ${fileSize.size} MB\n`);
        alert(`The maximum combined filesize is 8 MB.\n\nYou attempted to attach:\n${fileList}`);
        input.value = '';
        fileSizes = [];
        return false;
      }
    }
  })
}

/* ==================================================
New packaging tools:
===================================================*/
let packageBenefits = document.querySelector('.benefits-container');

if(packageBenefits) {
  const selectHotspot = (e) => {
    const clickedHotspot = e.target.parentElement;
    const container = clickedHotspot.parentElement;
    const hotspots = container.querySelectorAll(".benefits-hotspot");

    hotspots.forEach(hotspot => {
      if (hotspot === clickedHotspot) {
        hotspot.classList.toggle("benefits-hotspot--selected");
      } else {
        hotspot.classList.remove("benefits-hotspot--selected");
      }
    });
  }

  (() => {
    const buttons = document.querySelectorAll(".benefits-hotspot__button");
    buttons.forEach(button => {
      button.addEventListener("click", selectHotspot);
    });
  })();

  // Before after slider
  const slider = document.querySelector('.before-after-slider');
  const before = document.querySelector('.before-image');
  const beforeImage = before.querySelector('img');
  const resizer = document.querySelector('.resizer');
  let active = false;

  //Sort overflow out for Overlay Image
  document.addEventListener("DOMContentLoaded", function() {
    let width = slider.offsetWidth;
    if(width == 0) { return }
    beforeImage.style.width = width + 'px';
  });

  //Adjust width of image on resize
  window.addEventListener('resize', function() {
    let width = slider.offsetWidth;
    if(width == 0) { return }
    beforeImage.style.width = width + 'px';
  })

  resizer.addEventListener('mousedown',function(){
    active = true;
   resizer.classList.add('resize');

  });

  document.body.addEventListener('mouseup',function(){
    active = false;
   resizer.classList.remove('resize');
  });

  document.body.addEventListener('mouseleave', function() {
    active = false;
    resizer.classList.remove('resize');
  });

  document.body.addEventListener('mousemove',function(e){
    if (!active) return;
    let x = e.pageX;
    x -= slider.getBoundingClientRect().left;
    slideIt(x);
    pauseEvent(e);
  });

  resizer.addEventListener('touchstart',function(){
    active = true;
    resizer.classList.add('resize');
  });

  document.body.addEventListener('touchend',function(){
    active = false;
    resizer.classList.remove('resize');
  });

  document.body.addEventListener('touchcancel',function(){
    active = false;
    resizer.classList.remove('resize');
  });

  //calculation for dragging on touch devices
  document.body.addEventListener('touchmove',function(e){
    if (!active) return;
    let x;
    let i;

    for (i=0; i < e.changedTouches.length; i++) {
      x = e.changedTouches[i].pageX;
    }

    x -= slider.getBoundingClientRect().left;
    slideIt(x);
    pauseEvent(e);
  });

  function slideIt(x){
      let transform = Math.max(0,(Math.min(x,slider.offsetWidth)));
      before.style.width = transform+"px";
      if(x > slider.offsetWidth) {
          resizer.style.left = transform-4+"px";
      } else {
          resizer.style.left = transform-0+"px";
      }
  }

  //stop divs being selected.
  function pauseEvent(e){
      if(e.stopPropagation) e.stopPropagation();
      if(e.preventDefault) e.preventDefault();
      e.cancelBubble=true;
      e.returnValue=false;
      return false;
  }

  // Show hide different apps on page
  const buttonGroup = document.querySelectorAll('.button-group button');
  const beforeAfterSlider = document.querySelector('.before-after-slider');
  const showBeforeAfterSliderButton = document.querySelector('#show-before-after');
  const keyBenefits = document.querySelector('.benefits-container');
  const showKeyBenefitsButton = document.querySelector('#show-key-benefits');

  buttonGroup.forEach(button => button.addEventListener('click', function(e) {
    e.target.classList.add('active');
    if(e.target.id === "show-before-after") {
      showKeyBenefitsButton.classList.remove('active');
      keyBenefits.classList.remove('show');
      keyBenefits.classList.add('unshow');
      beforeAfterSlider.classList.remove('unshow');
      beforeAfterSlider.classList.add('show');
      return;
    }

    showBeforeAfterSliderButton.classList.remove('active');
    beforeAfterSlider.classList.remove('show');
    beforeAfterSlider.classList.add('unshow');
    keyBenefits.classList.remove('unshow');
    keyBenefits.classList.add('show');

  }))
}

// Site search
const SEARCHRESULTS_TEMPLATE = `
  <div class="addsearch-searchresults">
    {{#if resultcount}}
      {{> numberOfResultsTemplate }}
    {{/if}}
    {{#each hits}}
      <a href="{{url}}" data-analytics-click="{{id}}" class="hit{{#equals type "PROMOTED"}} promoted{{/equals}}">
          <h3>{{#if custom_fields.heading}}{{ custom_fields.heading }} {{else if title}} {{ title }}{{else}} {{removeTrailingQueriesFromUrl url}}{{/if}}</h3>
          <div class="highlight">
            {{> searchResultImageTemplate}}
            {{#if meta_description}}{{{meta_description}}}{{else}}{{{highlight}}}{{/if}}{{#not type "PROMOTED"}}&#8230;{{/not}}
          </div>
          {{#gt categories.length 1}}
            <div class="category">
              <span class="category-heading">Found in:</span>{{selectCategory ..}}
            </div>
          {{/gt}}
      </a>
    {{/each}}
  </div>
`;

function sendSearchToAnalytics(e) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'view_search_results',
    'num_results': e.numberOfResults,
    'search_or_click': e.action,
    'search_term': e.keyword
  });
}

// Modal search
let client = new AddSearchClient('1c3dceec7956ba41bdea0eab9529082c');
let config = {
  analyticsCallback: sendSearchToAnalytics
};
let searchui = new AddSearchUI(client, config);

searchui.searchField({
  selectorToBind: '#site-search-input',
  searchAsYouType: true
});

searchui.searchResults({
  containerId: 'site-search-autocomplete',
  template: SEARCHRESULTS_TEMPLATE
});

searchui.loadMore({
  containerId: 'site-search-load-more',
  type: AddSearchUI.LOAD_MORE_TYPE.BUTTON
});

searchui.start();

// Header search
let headersearchui = new AddSearchUI(client, config);

headersearchui.searchField({
  selectorToBind: '#header-search-input',
  searchAsYouType: true
});

headersearchui.searchResults({
  containerId: 'header-search-autocomplete',
  template: SEARCHRESULTS_TEMPLATE
});

headersearchui.loadMore({
  containerId: 'header-search-load-more',
  type: AddSearchUI.LOAD_MORE_TYPE.BUTTON
});

headersearchui.start();

// Blog search
let blogSearch = document.querySelector('#blog-search');
if(blogSearch) {
  let config = {
    updateBrowserHistory: false,
    baseFilters: {
      "category": "1xstandlee-barn-bulletin"
    },
    analyticsCallback: sendSearchToAnalytics
  }
  var blogSearchui = new AddSearchUI(client, config);

  blogSearchui.searchField({
    selectorToBind: '#blog-search',
    searchAsYouType: true
  });

  blogSearchui.searchResults({
    containerId: 'blog-search-autocomplete',
    template: SEARCHRESULTS_TEMPLATE
  });

  blogSearchui.loadMore({
    containerId: 'blog-search-load-more',
    type: AddSearchUI.LOAD_MORE_TYPE.BUTTON
  });

  blogSearchui.start();
}

/**
 * A lightweight youtube embed. Still should feel the same to the user, just MUCH faster to initialize and paint.
 *
 * Thx to these as the inspiration
 *   https://storage.googleapis.com/amp-vs-non-amp/youtube-lazy.html
 *   https://autoplay-youtube-player.glitch.me/
 *
 * Once built it, I also found these:
 *   https://github.com/ampproject/amphtml/blob/master/extensions/amp-youtube (👍👍)
 *   https://github.com/Daugilas/lazyYT
 *   https://github.com/vb/lazyframe
 */
class LiteYTEmbed extends HTMLElement {
    connectedCallback() {
        this.videoId = this.getAttribute('videoid');

        let playBtnEl = this.querySelector('.lty-playbtn');
        // A label for the button takes priority over a [playlabel] attribute on the custom-element
        this.playLabel = (playBtnEl && playBtnEl.textContent.trim()) || this.getAttribute('playlabel') || 'Play';

        /**
         * Lo, the youtube placeholder image!  (aka the thumbnail, poster image, etc)
         *
         * See https://github.com/paulirish/lite-youtube-embed/blob/master/youtube-thumbnail-urls.md
         *
         * TODO: Do the sddefault->hqdefault fallback
         *       - When doing this, apply referrerpolicy (https://github.com/ampproject/amphtml/pull/3940)
         * TODO: Consider using webp if supported, falling back to jpg
         */
        if (!this.style.backgroundImage) {
          this.style.backgroundImage = `url("https://i.ytimg.com/vi/${this.videoId}/hqdefault.jpg")`;
        }

        // Set up play button, and its visually hidden label
        if (!playBtnEl) {
            playBtnEl = document.createElement('button');
            playBtnEl.type = 'button';
            playBtnEl.classList.add('lty-playbtn');
            this.append(playBtnEl);
        }
        if (!playBtnEl.textContent) {
            const playBtnLabelEl = document.createElement('span');
            playBtnLabelEl.className = 'lyt-visually-hidden';
            playBtnLabelEl.textContent = this.playLabel;
            playBtnEl.append(playBtnLabelEl);
        }
        playBtnEl.removeAttribute('href');

        // On hover (or tap), warm up the TCP connections we're (likely) about to use.
        this.addEventListener('pointerover', LiteYTEmbed.warmConnections, {once: true});

        // Once the user clicks, add the real iframe and drop our play button
        // TODO: In the future we could be like amp-youtube and silently swap in the iframe during idle time
        //   We'd want to only do this for in-viewport or near-viewport ones: https://github.com/ampproject/amphtml/pull/5003
        this.addEventListener('click', this.addIframe);

        // Chrome & Edge desktop have no problem with the basic YouTube Embed with ?autoplay=1
        // However Safari desktop and most/all mobile browsers do not successfully track the user gesture of clicking through the creation/loading of the iframe,
        // so they don't autoplay automatically. Instead we must load an additional 2 sequential JS files (1KB + 165KB) (un-br) for the YT Player API
        // TODO: Try loading the the YT API in parallel with our iframe and then attaching/playing it. #82
        this.needsYTApiForAutoplay = navigator.vendor.includes('Apple') || navigator.userAgent.includes('Mobi');
    }

    /**
     * Add a <link rel={preload | preconnect} ...> to the head
     */
    static addPrefetch(kind, url, as) {
        const linkEl = document.createElement('link');
        linkEl.rel = kind;
        linkEl.href = url;
        if (as) {
            linkEl.as = as;
        }
        document.head.append(linkEl);
    }

    /**
     * Begin pre-connecting to warm up the iframe load
     * Since the embed's network requests load within its iframe,
     *   preload/prefetch'ing them outside the iframe will only cause double-downloads.
     * So, the best we can do is warm up a few connections to origins that are in the critical path.
     *
     * Maybe `<link rel=preload as=document>` would work, but it's unsupported: http://crbug.com/593267
     * But TBH, I don't think it'll happen soon with Site Isolation and split caches adding serious complexity.
     */
    static warmConnections() {
        if (LiteYTEmbed.preconnected) return;

        // The iframe document and most of its subresources come right off youtube.com
        LiteYTEmbed.addPrefetch('preconnect', 'https://www.youtube-nocookie.com');
        // The botguard script is fetched off from google.com
        LiteYTEmbed.addPrefetch('preconnect', 'https://www.google.com');

        // Not certain if these ad related domains are in the critical path. Could verify with domain-specific throttling.
        LiteYTEmbed.addPrefetch('preconnect', 'https://googleads.g.doubleclick.net');
        LiteYTEmbed.addPrefetch('preconnect', 'https://static.doubleclick.net');

        LiteYTEmbed.preconnected = true;
    }

    fetchYTPlayerApi() {
        if (window.YT || (window.YT && window.YT.Player)) return;

        this.ytApiPromise = new Promise((res, rej) => {
            var el = document.createElement('script');
            el.src = 'https://www.youtube.com/iframe_api';
            el.async = true;
            el.onload = _ => {
                YT.ready(res);
            };
            el.onerror = rej;
            this.append(el);
        });
    }

    async addYTPlayerIframe(params) {
        this.fetchYTPlayerApi();
        await this.ytApiPromise;

        const videoPlaceholderEl = document.createElement('div')
        this.append(videoPlaceholderEl);

        const paramsObj = Object.fromEntries(params.entries());

        new YT.Player(videoPlaceholderEl, {
            width: '100%',
            videoId: this.videoId,
            playerVars: paramsObj,
            events: {
                'onReady': event => {
                    event.target.playVideo();
                }
            }
        });
    }

    async addIframe(){
        if (this.classList.contains('lyt-activated')) return;
        this.classList.add('lyt-activated');

        const params = new URLSearchParams(this.getAttribute('params') || []);
        params.append('autoplay', '1');
        params.append('playsinline', '1');

        if (this.needsYTApiForAutoplay) {
            return this.addYTPlayerIframe(params);
        }

        const iframeEl = document.createElement('iframe');
        iframeEl.width = 560;
        iframeEl.height = 315;
        // No encoding necessary as [title] is safe. https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#:~:text=Safe%20HTML%20Attributes%20include
        iframeEl.title = this.playLabel;
        iframeEl.allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
        iframeEl.allowFullscreen = true;
        // AFAIK, the encoding here isn't necessary for XSS, but we'll do it only because this is a URL
        // https://stackoverflow.com/q/64959723/89484
        iframeEl.src = `https://www.youtube-nocookie.com/embed/${encodeURIComponent(this.videoId)}?rel=0&enablejsapi=1&${params.toString()}`;
        this.append(iframeEl);

        // Set focus for a11y
        iframeEl.focus();
    }
}
// Register custom element
customElements.define('lite-youtube', LiteYTEmbed);
